import React from "react"
import Seo from "@components/seo"
import Banner from "@components/banner/Banner"
import { Spacer } from "@styles/utilityClasses"
import Cta from "@components/cta/cta"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    headlines: allSanityLandingPageBanner(sort: { fields: index, order: ASC }) {
      edges {
        node {
          id
          headline
          subheadline
          index
          primaryButton {
            text
            link
          }
          secondaryButton {
            text
            link
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const banner = data.headlines.edges
  // console.log("Banner", banner)
  return (
    <>
      <Seo title="Home" />
      {banner.map(({ node }) => {
        const {
          headline,
          subheadline,
          index,
          id,
          primaryButton,
          secondaryButton,
        } = node
        return (
          <Banner
            headline={headline}
            paragraph={subheadline}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            index={index}
            key={id}
          />
        )
      })}
      <Cta
        title="letter from the ceo"
        paragraph=""
        slug="/letter-from-the-ceo"
        linkTitle="read more"
      />
      <Spacer />
      <Spacer />
    </>
  )
}

export default IndexPage
