import React from "react"
// import { Qed } from "@styles/components/bannerStyles"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const Path = styled.path`

`

const Qed = styled(motion.svg)`

${Path} {
    fill: ${p => p.theme.fillColor};
  }

`


const QedMark = ({ variants }) => {
  return (
    <Qed
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      variants={variants}
    >
      <path
        d="M9.59973 11.1992L14.9329 14.3991L5.59985 19.999L0 16.7991L9.59973 11.1992Z"
        fill="url(#paint0_linear_720_103351)"
      />
      <Path
        d="M2.46735e-07 5.60059L5.59984 8.80047V20.0002L0 16.8003L2.46735e-07 5.60059Z"
        fill="#EAEAEA"
      />
      <Path
        d="M9.59961 0L14.9328 3.19991V14.3996L9.59961 11.1997V0Z"
        fill="#EAEAEA"
      />
      <Path
        d="M9.59973 0L14.9329 3.19991L5.59984 8.79975L0 5.59985L9.59973 0Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_720_103351"
          x1="6.53315"
          y1="19.999"
          x2="10.0137"
          y2="11.7449"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#979797" />
          <stop offset="0.078125" stopColor="#979797" />
          <stop offset="0.9999" stopColor="#E0E0E0" />
        </linearGradient>
      </defs>
    </Qed>
  )
}

export default QedMark

QedMark.propTypes = {
  variants: PropTypes.object,
}
