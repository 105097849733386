import styled from "styled-components"
import { motion } from "framer-motion"
import { MediumDisplay } from "@styles/typography"
import { Link } from "gatsby"

const CTAContainer = styled(motion.div)`
  width: 100%;
  text-align: center;
  margin-top: 10rem;
  padding: 2rem 0;
`

const CtaTitle = styled(MediumDisplay)``

const Paragraph = styled(motion.p)`
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.5px;
  max-width: 50ch;
  margin-inline: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${p => p.theme.bodyParagraphColor};
`

const CtaButton = styled(Link).attrs({
  to: "/letter-from-the-ceo",
  className: "button",
  })`
  text-decoration: none;
  padding: 8px 16px;
  background: ${p => p.theme.primaryColor};
  color: ${p => p.theme.bodyTextColor};
  border-radius: 40px;
  border: 1px solid ${p => p.theme.bodyTextColor};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => (props.block ? "display: block; width: 100%;" : "")}
  font-family:  var(--body-text-regular);
  ${props => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
  transition: all 0.3s ease-in-out;
  
  &:hover{
    background: ${p => p.theme.elevation2};);
    border: 1px solid ${p => p.theme.elevation2};
  }
`

export { CTAContainer, CtaTitle, Paragraph, CtaButton }
