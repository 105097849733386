import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import {
  InViewContainer,
  BannerWrap,
  Title,
  Paragraph,
  ButtonContainer,
  PrimaryButton,
  TitleSpan,
} from "./banner.styles"
import QedMark from "@components/common/QED/qed"
import { Modal } from "@components"
import { bannerVariants, letter } from "./bannerData"

const Banner = ({ headline, paragraph, index, primaryButton }) => {
  const animation = useAnimation()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  const [showModal, setShowModal] = useState(false)
  const isEven = index % 2 === 0

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    }
  }, [animation, inView])

  if (isEven) {
    return (
      <InViewContainer
        ref={ref}
        animate={animation}
        initial="hidden"
        variants={bannerVariants}
      >
        <BannerWrap as="section">
          <Title>
            {headline.split("").map((char, i) => (
              <TitleSpan key={char + "-" + i} variants={letter}>
                {char}
              </TitleSpan>
            ))}

            <QedMark variants={letter} />
          </Title>
          <Paragraph variants={letter}>{paragraph}</Paragraph>
          <ButtonContainer variants={letter}>
            {primaryButton && (
              <PrimaryButton to={`${primaryButton.link}`}>
                {primaryButton.text}
              </PrimaryButton>
            )}
            <Modal showModal={showModal} setShowModal={setShowModal} />
          </ButtonContainer>
        </BannerWrap>
      </InViewContainer>
    )
  } else {
    const isOdd = isEven ? "" : "is-odd"
    return (
      <InViewContainer
        ref={ref}
        animate={animation}
        initial="hidden"
        variants={bannerVariants}
      >
        <BannerWrap as="section" className={isOdd} isOdd={!isEven}>
          <Title isOdd={isOdd}>
            {headline.split("").map((char, i) => (
              <TitleSpan key={char + "-" + i} variants={letter}>
                {char}
              </TitleSpan>
            ))}

            <QedMark variants={letter} />
          </Title>
          <Paragraph className={isOdd} variants={letter}>
            {paragraph}
          </Paragraph>
          <ButtonContainer className={isOdd} variants={letter}>
            {primaryButton && (
              <PrimaryButton to={`${primaryButton.link}`}>
                {primaryButton.text}
              </PrimaryButton>
            )}
          </ButtonContainer>
        </BannerWrap>
      </InViewContainer>
    )
  }
}

export default Banner

Banner.propTypes = {
  headline: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
}

Banner.defaultProps = {
  headline: "",
  paragraph: "",
}
