import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { bannerVariants, letter } from "@components/banner/bannerData"
import { CTAContainer, CtaTitle, Paragraph, CtaButton } from "./cta.styles"

const Cta = ({ title, paragraph, slug, linkTitle }) => {
  const controls = useAnimation()

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <CTAContainer
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={bannerVariants}
    >
      <CtaTitle>
        {title.split("").map((char, i) => (
          <motion.span key={char + "-" + i} variants={letter}>
            {char}
          </motion.span>
        ))}
      </CtaTitle>
      <Paragraph variants={letter}>{paragraph}</Paragraph>
      <motion.span variants={letter}>
        <CtaButton to={slug}>{linkTitle}</CtaButton>
      </motion.span>
    </CTAContainer>
  )
}

export default Cta

Cta.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
}

Cta.defaultProps = {
  title: "",
  paragraph: "",
  slug: "",
  linkTitle: "",
}
