import styled from "styled-components"
import { MediumDisplay } from "@styles/typography"
import media from "css-in-js-media"
import { Grid } from "@styles/utilityClasses"
import { motion } from "framer-motion"
// import { Button } from "@components/common/form"
import { Link } from "gatsby"

const InViewContainer = styled(motion.div)`
  scroll-snap-type: y mandatory;
  position: relative;
  margin: 3rem 3rem;
  
  ${media("<tablet")} {
    margin: 8rem 0;
  }
`

const BannerWrap = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
  max-width: 1250px;
  box-sizing: border-box;
  margin: auto;
  ${media(">desktop")} {
  }
  ${media("<=desktop", ">tablet")} {
    margin-top: 5vh;
    max-width: 500px;
  }
  ${media("<=tablet", ">phone")} {
    display: block;
    padding: 0 1rem;
  }
  ${media("<=phone")} {
  }
`

const Title = styled(MediumDisplay)`
  ${p => (p.isOdd ? `text-align: right;` : `text-align: left;`)}
  ${media(">desktop")} {}
  ${media("<=desktop", ">tablet")} {
    text-align: center;
  }

  ${media("<=tablet", ">phone")} {
    text-align: center;
  }

  .is-odd {
   text-align: center;
  }
`

const Paragraph = styled(motion.p)`
  /* font-size: 1rem; */
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 0 auto;
  color: ${p => p.theme.bodyParagraphColor};
  align-self: start;
  max-width: 700px;
  /* margin-inline: auto; */

  ${media(">desktop")} {
    /* font-size: 1.2rem; */
    line-height: 28px;
    letter-spacing: 0.5px;
    /* align-self: center; */
  }

  ${media("<=desktop", ">tablet")} {
    /* font-size: 1.2rem; */
    line-height: 28px;
    letter-spacing: 0.5px;
    text-align: center;
    align-self: center;
    margin-inline: auto;
  }

  ${media("<=tablet", ">phone")} {
    margin-inline: auto;
  }


  &.is-odd {
    text-align: right;
  align-self: end;

    ${media(">desktop")} {
    
    }

    ${media("<=desktop", ">tablet")} {
      /* grid-column: 1 / 6; */
      text-align: center;
    }
    ${media("<tablet", ">phone")} {
      text-align: center;
    }
  }

  

  ${media(">=desktop")} {}
  ${media("<=desktop", ">tablet")} {
    text-align: center;
    justify-self: center;
  }
  ${media("<=tablet", ">phone")} {
    text-align: center;
    justify-self: center;
    padding: 1rem 0;
  }
  ${media("<=phone")} {
    /* display: grid; */
  }
`

const ButtonContainer = styled(motion.span)`
  grid-row: 3;
  display: flex;
  gap: 1rem;
  /* grid-column: ${p => (p.isOdd ? " 7 / 12" : " 1 / 7")};
  align-items: ${p => (p.isOdd ? "end" : "start")};
  justify-content: ${p => (p.isOdd ? "flex-end" : "flex-start")}; */

  ${media("<desktop", ">tablet")} {
    align-items: center;
    justify-content: center;
  }
  ${media("<=tablet", ">phone")} {
    align-items: center;
    justify-content: center;
  }

  &.is-odd {
    grid-column: 7 / 12;
    align-items: end;
    justify-content: flex-end;

    ${media("<desktop", ">tablet")} {
      align-items: center;
      justify-content: center;
      
    }

    ${media("<=tablet", ">phone")} {
      align-items: center;
      justify-content: center;
    }
  }
  grid-column: 1 / 7;
`

// const BannerButton = styled(Button)`
//   /* font-size: 1rem;
//   color: #fff;
//   background-color: inherit;
//   border: 1px solid #fff;
//   border-radius: 8px;
//   padding: 0.5rem 1rem;
//   grid-row: 3;
//   width: 100%; */
//   margin: 0 10px 10px 0;
// `

const TitleSpan = styled(motion.span)``



const SecondTaglineWrap = styled(Grid)`
  /* grid-column: 9 / span 8; */
`

const SecondTaglineTitle = styled(MediumDisplay)`
  grid-column: 7 / span 6;
  /* text-align: right; */

  ${media(">desktop")} {
  }
  ${media("<=desktop", ">tablet")} {
    grid-column: 2 / 6;
    text-align: center;
  }
  ${media("<=tablet", ">phone")} {
  }
`

const SecondTaglineParagraph = styled(motion.p)`
  margin-top: 1.5rem;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 8px 0;
  color: #999;

  align-self: end;

  ${media(">desktop")} {
    grid-column: 2 / span 6;
  }
  ${media("<=desktop", ">tablet")} {
    /* grid-column: 2 / 6; */
    text-align: center;
    justify-self: center;
  }
  ${media("<=tablet", ">phone")} {
  }
  ${media("<=phone")} {
  }
`

const PrimaryButton = styled(Link)`
grid-column: ${p => (p.isOdd ? "10" : " 1")};
text-decoration: none;
  padding: 8px 16px;
  background: ${p => p.theme.primaryColor};
  color: ${p => p.theme.bodyTextColor};
  border-radius: 40px;
  border: 1px solid ${p => p.theme.bodyTextColor};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  /* text-align: center; */
  cursor: pointer;
  white-space: nowrap;
  ${props => (props.block ? "display: block; width: 100%;" : "")}
  font-family:  var(--body-text-regular);
  ${props => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
  transition: all 0.3s ease-in-out;
  

  /* grid-column-start: 1;
grid-column-end: 3; */
  &:hover{
    background: ${p => p.theme.elevation2};);
    border: 1px solid ${p => p.theme.elevation2};
  }
  /* margin: 0 10px 10px 0; */
`

const SecondaryButton = styled(Link)`
  /* grid-row: 3 */
  grid-column: ${p => (p.isOdd ? "11" : "2")};
  padding: 8px 16px;
  background: ${p => p.theme.fillColor};
  color: ${p => p.theme.primaryColor};
  border-radius: 40px;
  border: 1px solid ${p => p.theme.fillColor};
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;
  ${props => (props.block ? "display: block; width: 100%;" : "")}
  font-family:  var(--body-text-regular);
  ${props => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")}
  transition: all 0.3s ease-in-out;

  
  &:hover{
    background: ${p => p.theme.primaryColor};);
    color: ${p => p.theme.fillColor};
    border: 1px solid ${p => p.theme.fillColor};
  }
  /* margin: 0 10px 10px 0; */

`

// second tagline end

// third tagline start
// third tagline end

export {
  InViewContainer,
  BannerWrap,
  Title,
  Paragraph,
  ButtonContainer,
  TitleSpan,
  // Qed,
  SecondTaglineWrap,
  SecondTaglineTitle,
  SecondTaglineParagraph,
  PrimaryButton,
  SecondaryButton,
}
